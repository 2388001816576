import React, { useEffect } from 'react';
import styled from 'styled-components';
import { H3, Body, H6 } from '../../util/typography';
import * as colors from '../../util/colors';
import { useSelector, useDispatch } from 'react-redux';
import {
    openHcpAcknowledgement,
    closeHcpAcknowledgement,
    allowScrolling,
    preventScrolling,
} from '../../store/actions';
import { ARE_YOU_AN_HCP_MODAL_OVERLAY } from '../../constants/z-index-map';
import { HAS_ACKNOWLEDGED_HCP_KEY } from '../../store/storage-keys';
import Button from '../Button';
import { respondTo } from '../../util/breakpoints';
import arrowRight from '../../assets/utility/icons/icon-button-arrow.svg';
import externalIcon from '../../assets/utility/icons/icon-button-arrow-external.svg';
import { externalDotWhite, arrowExternalWhite, arrowRightGreenDark, arrowRightGreenRollover, arrowRightWhite } from '../../util/icons';


const ModalOverlay = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(38, 38, 38, 0.8);
    z-index: ${ARE_YOU_AN_HCP_MODAL_OVERLAY};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const ModalWrapper = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
`;

const ModalContainer = styled.div`
    max-width: 800px;
    margin: 20px;
    border-radius: 8px;
    background: ${colors.white};
    max-height: calc(100% - 40px);
    overflow: auto;
    overscroll-behavior: contain;
`;

const ModalContent = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;

    > div {
        box-sizing: border-box;
        padding: 20px 15px;
    }
    ${respondTo.md`
        > div {
            padding: 40px;
        }
    `}
`;

const ContentContainer = styled.div`
    text-align: center;
    color: ${colors.charcoal};
`;

const CTARow = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    flex-wrap: wrap;

    ${respondTo.md`
        flex-direction: row;
        margin: 19px 0;
    `}

    > button,
    > a {
        flex: 1 1 150px;
        margin: 20px 10px 0;
        justify-content: center;
    }
`;

const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 19px 0 20px 0;
    align-items: center;
    &:nth-child(3){
        padding: 20px 0 19px 0;
    }

    ${respondTo.md`
        padding: 0 84px 0 0px;
        align-items: center;
        height: 102px;
        &:nth-child(3){
            padding: 0 0 0 84px;
        }
    `}
`;

const Title = styled(H3)`
    
`;

const Descriptor = styled(H6)`
    margin-bottom: 20px;
`;

const Divider = styled.div`
    background-color: ${colors.pebble};
    width: auto;
    height: 1px;

    ${respondTo.md`
        width: 1px;
        height: auto;
    `}
`;

const DisclaimerText = styled(Body)`
    display: block;
    text-align: center;
    margin: 10px 0 0;

    ${respondTo.md`
        margin: 19px 0 0;
    `}
`;

const VisitButton = styled(Button)`
     position: relative;
    padding-right: 44px;
    background-size: 201% 100%;
    background-origin: border-box;
    transition: background-position 0.3s ease-in-out;
    width: 180px;
    height: 43px;
    padding-right: 0px;
    &:hover{
            &:after {
                animation-duration: 0.3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;  
            }
        
    }
    @keyframes arrowRight-animation {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }
    &:after {
        content: '';
        position: absolute;
        right: 16px;
        background: url(${arrowRightWhite});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-position: center left;
        
    }
`;

const ExternalButton = styled(Button)`
    position: relative;
    width: 180px;
    height: 43px;
    padding-right: 0px;
    
    &:after {
        content: '';
        position: absolute;
        right: 12px;
        background-image: url(${externalDotWhite}), url(${arrowExternalWhite});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        background-position: 0% 100%,60% 40%,left;
        background-size: 5px 7px,11px 12px,200% 100%;
    }
    &:hover {
        &:after {
            animation-duration: 0.4s;
            animation-name: external-animation1;
        }
    }
    
    @keyframes external-animation1 {
        0% {
            background-position: 0% 100%, center 50%, left;
        }

        50% {
            background-position: 0% 100%, calc(100% + 10px) calc(0% - 10px), center;
        }

        50.001% {
            background-position: 0% 100%, calc(0% - 10px) calc(100% + 10px), center;
        }

        100% {
            background-position: 0% 100%, center 50%, right;
        }
    }


`;



const HCPAcknowledgement = ({ hcp_acknowledgement_ref }) => {
    const hcpAcknowledgementOpen = useSelector(state => state.hcpAcknowledgementOpen);
    const dispatch = useDispatch();

    const clickHandler = () => {
        if (typeof window !== 'undefined' && window.location.protocol !== 'data:') {
            window.sessionStorage.setItem(HAS_ACKNOWLEDGED_HCP_KEY, '1');
            const modalOverlay = document.querySelector('.onetrust-pc-dark-filter');
            if(modalOverlay)
            modalOverlay.style.background = 'rgba(0, 0, 0, 0.7)';
        }
        dispatch(closeHcpAcknowledgement());
        dispatch(allowScrolling());
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && window.origin !== 'null') {
            let has_acknowledged = window.sessionStorage.getItem(HAS_ACKNOWLEDGED_HCP_KEY);
            if (!has_acknowledged && !hcpAcknowledgementOpen) {
                dispatch(openHcpAcknowledgement());
                dispatch(preventScrolling());
            }
        }
    }, [dispatch, hcpAcknowledgementOpen]);

    return (
        <>
            {hcpAcknowledgementOpen && (
                <ModalOverlay ref={hcp_acknowledgement_ref}>
                    <ModalWrapper>
                        <ModalContainer>
                            <ModalContent>
                                <ContentContainer>
                                    <Title>This site is intended for health care professionals</Title>
                                    <CTARow>
                                        <CTAContainer>
                                            <Descriptor>I am a health care professional</Descriptor>
                                            <VisitButton
                                                onClick={clickHandler}
                                                type='primary-dark'
                                                noIcon
                                            >Proceed to site</VisitButton>
                                        </CTAContainer>
                                        <Divider />
                                        <CTAContainer>
                                            <Descriptor>I am a patient or caregiver</Descriptor>
                                            <ExternalButton
                                                type='primary-dark'
                                                to='https://keytrudalenvima.com/'
                                                noIcon
                                                // icon={externalIcon}
                                            >Visit patient site</ExternalButton>
                                        </CTAContainer>
                                    </CTARow>
                                    <DisclaimerText>
                                        The information on this site is intended for health care professionals in the United States and its territories, and is not intended for the general public.
                                    </DisclaimerText>
                                </ContentContainer>
                            </ModalContent>
                        </ModalContainer>
                    </ModalWrapper>
                </ModalOverlay>
            )}
        </>
    );
};

export default HCPAcknowledgement;
